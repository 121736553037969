.container {
	background: white;
	/* border-radius: 10px; */
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0px;
	padding-bottom: 20px;
	box-sizing: border-box;
	overflow-y: auto;
}

.candidate-section-button {
	color: black;
	font-weight: var(--bold);
	opacity: 0.8;
	font-size: 16px;
}

.list-nav,
.candidate-menu {
	background: white;
	/* border-radius: 6px; */
	padding: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 50px;
	box-sizing: border-box;
}

.candidate-menu {
	padding-right: 20px;
}

.list-nav {
	width: 100%;
}

.list-nav button {
	border: 0;
	background: transparent;
}

.list-nav button:hover {
	cursor: pointer;
}

.list-nav button {
	border: 0;
	background: transparent;
	font-size: 16px;
	display: flex;
	align-items: center;
	font-weight: var(--bold);
}

.list-nav div {
	display: flex;
}

.name {
	font-weight: var(--bold);
	padding: 0 100px 0 10px;
}

.info-container {
	display: flex;
	flex-direction: column;
	padding: 10px 10px 10px 20px;
	gap: 20px;
	width: 100%;
	/* border-radius: 10px; */
	background: white;
	align-items: flex-start;
	box-sizing: border-box;
}

.grid-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	flex-grow: 1;
}

.metrics-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	align-items: center;
	width: 100%;
	height: 120%;
	transform: translateY(-10%);
}

.metric {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-size: 14px;
}

.metric > span {
	padding-left: 40px;
	font-weight: var(--bold);
	text-align: left;
}

.metric > .title {
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	opacity: 0.8;
	margin-top: auto;
	font-weight: var(--bold);
}

.graph-container {
	width: 45%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: white;
	padding: 20px;
	/* border-radius: 10px; */
}

.container-label {
	font-weight: var(--bold);
	padding-bottom: 20px;
}

.comments-container {
	display: flex;
	flex-direction: column;
	max-height: 350px;
	overflow-y: auto;
}

.comment {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-bottom: 1px solid rgb(0, 0, 0, 0.3);
	margin-bottom: 20px;
}

.comment:last-child {
	margin-bottom: 0;
}

.comment > label {
	font-weight: var(--bold);
}

.comment > p {
	text-align: left;
}
