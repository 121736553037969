.instruction-text {
	font-size: 16px;
	width: 350px;
	padding: 20px 0;
}

.text-input {
	width: 300px;
	margin-top: 20px;
}

.submit-button {
	color: white;
	text-transform: none;
	width: 310px;
	margin-top: 30px;
	border: none;
	padding: 8px 0;
	font-size: 16px;
	border-radius: 0.3rem;
	font-family: 'Poppins', sans-serif;
}

.submit-button:hover {
	cursor: pointer;
}

.small-button {
	text-align: left;
	width: fit-content;
	text-transform: none;
	font-size: 14px;
	font-weight: normal;
	padding-left: 0;
	margin: 10px 0 0 0;
	min-width: 0;
	border: none;
}

.error-message,
.success-message {
	width: 300px;
	text-align: center;
	font-size: 14px;
	margin: 20px 0 10px;
}

.error-message {
	color: red;
}

.success-message {
	font-weight: bold;
	color: #32a846;
}
