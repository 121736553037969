.container {
	padding: 20px;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
}

.container-description {
	font-size: 14px;
	opacity: 0.9;
	text-align: left;
}

.label {
	display: flex;
	gap: 10px;
	font-size: 18px;
	font-weight: var(--bold);
	color: #001940;
	align-items: center;
}

.label-icon {
	/* background: #001940; */
	/* color: white; */
	color: #001940;
	border-radius: 0.5rem;
	display: grid;
	place-content: center;
	height: 35px;
	width: 35px;
}

.metric-grid {
	display: grid;
	margin-left: 5px;
	text-align: left;
	grid-template-columns: 150px 1fr;
	grid-template-rows: 1fr 1fr;
	row-gap: 10px;
}

.metric-label {
	font-size: 14px;
	color: var(--faded-black);
	margin-top: auto;
	font-weight: var(--bold);
}

.metric-amount {
	font-weight: var(--bold);
	text-align: left;
	font-size: 14px;
}

.application-numbers-row {
	display: flex;
	gap: 20px;
	/* margin-left: 10px; */
}

.application-numbers {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 3px;
}

.application-numbers > span:first-child {
	font-weight: var(--bold);
	opacity: 0.7;
	color: black;
}
