.container-label {
	font-size: 18px;
	font-weight: var(--bold);
	color: black;
}

.container-description {
	font-size: 14px;
	margin: 5px 0 0 0;
	opacity: 0.9;
}
