.search-container {
	padding: 10px 20px 10px 20px;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	/* outline: 1px solid rgb(60, 60, 60, 0.5); */
	/* box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px; */
	background: white;
	color: rgb(40, 40, 40);
}
