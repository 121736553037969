.metric {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.metric > .title {
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	font-size: 14px;
	opacity: 0.8;
	margin-top: auto;
	font-weight: var(--bold);
}

.metric > span {
	font-weight: var(--bold);
	text-align: left;
	font-size: 14px;
}

.table-button-row {
	display: flex;
	align-self: flex-end;
	gap: 40px;
	padding: 20px 30px 0 30px;
}

.test {
	font-size: 16px;
	opacity: 0.8;
	font-weight: var(--bold);
	color: black;
}

.allowed-uses-button:hover {
	background: rgb(0, 0, 0, 0.1);
}
