.container {
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px;
}

.dashboard-items-column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 90%;
	width: 20%;
}

.graph-table-container {
	display: flex;
	width: 70%;
	height: 90%;
	/* padding: 12px 0px; */
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.item-container {
	background: white;
	/* border-radius: 1rem; */
	width: 230px;
	height: 160px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.item-title {
	display: flex;
	gap: 8px;
	font-weight: var(--bold);
	font-size: 18px;
	color: #343434;
	align-items: center;
}

.icon-container {
	padding: 3px;
	/* border-radius: 0.5rem; */
	background-color: #001940;
	display: grid;
	place-content: center;
}

.candidates-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	width: 90%;
	height: 120px;
	padding-top: 10px;
	row-gap: 10px;
	/* outline: 2px solid red; */
}

.candidates-metric {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.candidates-metric-title {
	font-size: 14px;
	font-weight: var(--bold);
	opacity: 0.7;
	color: #343434;
	text-transform: uppercase;
}

.candidates-metric-amount {
	display: flex;
	justify-content: center;
	gap: 5px;
	align-items: center;
	font-size: 16px;
	font-weight: var(--semi-bold);
	color: #343434;
}

.show-green-dot::before,
.show-red-dot::before {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 1rem;
	background: #32a846;
	content: '';
}

.show-red-dot::before {
	background: #cc0000;
}

/* ################################################################################################ */
/* ################################################################################################ */
/* ################################################################################################ */
/* ################################################################################################ */

#graph-section {
	width: 100%;
	background: white;
	/* margin-top: 25px; */
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 25px 0 25px 0;
	height: 100%;
}

#graph-button-group {
	display: flex;
	justify-content: space-between;
	width: 280px;
	margin: 0 0 10px 50px;
}

#graph-button-group button {
	background: #001940;
	border: none;
	color: white;
	height: 30px;
	width: 80px;
	border-radius: 6px;
	opacity: 0.6;
	font-family: 'Poppins', sans-serif;
}

#graph-button-group button.active {
	opacity: 1;
}

#graph-button-group button:hover {
	opacity: 1;
	cursor: pointer;
	transition: all 0.5s;
}

@media screen and ((max-width: 1300px) or (max-height: 825px)) {
	.container {
		flex-direction: column;
		overflow-y: auto;
		align-items: center;
		justify-content: start;
	}

	.dashboard-items-column {
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		margin: 30px 0 15px;
		height: 200px;
	}

	.graph-table-container {
		/* The 3 'dashboard-item' compoents are arranged _x_x_x_ where _ are equal spaces and x are the components. We want our graph and table
		   to match the x_x_x section, so the follow calculation is done. (100% - 270px * 3) gets the total length of the spaces. We multiply by
		   2/4 = 1/2 because we want half of the spaces. Then we add the width of the components (taking into account their padding (20px * 2)). */
		width: calc((100% - 270px * 3) * 1 / 2 + 270px * 3);

		height: 700px;
		align-items: center;
		gap: 10px;
	}
}

@media screen and (max-width: 1175px) {
	.item-container {
		padding: 20px 10px;
	}
	.graph-table-container {
		width: calc((100% - 250px * 3) * 1 / 2 + 250px * 3);
	}
}
