.custom-table {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
	margin: auto;
}

.custom-table * {
	font-size: 14px !important;
}

.custom-table th * {
	font-weight: var(--semi-bold);
}

.custom-table tr {
	background: white;
	box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px;
}

.custom-table thead tr:first-child {
	box-shadow: none;
}

.custom-table thead {
	background: #e8e8e8;
	/* position: sticky; */
	top: 0;
}

.custom-table td {
	padding: 10px 0;
}

.custom-table th {
	padding: 10px 0;
	font-weight: var(--semi-bold);
	opacity: 0.9;
}
