.Info-Bar {
    min-height: 75px; 
    width: 100%; 
    background: #001940;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Container {
    flex: 1 1 auto;
    overflow: auto;
}