.menu-button {
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 16px;
	font-family: 'Poppins', sans-serif;

	height: fit-content;
	background: none;
	color: white;
	border: none;
	transition: transform 0.25s;
	text-transform: none;
}

.menu-button:hover {
	opacity: 0.6;
	transform: translateY(-1px);
	cursor: pointer;
}

.button-style-one {
	opacity: 0.8;
	font-weight: var(--bold);
	color: black;
}
