@import url('https://fonts.googleapis.com/css?family=Roboto');

.NavigationBar {
	background-color: #001940;
	height: 100%;
	width: 170px;
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 6px 0 0 6px;
	transition: all 0.5s cubic-bezier(0.45, 0.02, 0.09, 0.98);
	overflow-x: hidden;
	z-index: 1000;
}

.button-text {
	text-transform: none;
	font-size: 16px;
	margin-left: 10px;
	text-align: start;
	font-family: 'Poppins', sans-serif;
	animation: fade-in-fast 0.75s cubic-bezier(0.45, 0.02, 0.09, 0.98);
}

@keyframes fade-in-fast {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
