.nav-bar {
	height: 50px;
	padding: 10px 20px;
	background: white;
	display: flex;
	gap: 30px;
}

.nav-button {
	border: 0;
	padding: 0;
	background: transparent;
	display: flex;
	flex-direction: column;
	font-weight: var(--bold);
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	gap: 3px;
}

.nav-button > span:nth-child(2) {
	font-size: 14px;
	font-weight: normal;
	text-transform: none;
	opacity: 0.6;
}

.nav-button:hover {
	cursor: pointer;
}

@media screen and (max-width: 1120px) {
	.nav-button > span:nth-child(2) {
		display: none;
	}

	.nav-bar {
		align-items: center;
	}
}

.container-description {
	font-size: 14px;
	margin: 2px 0 8px 0;
	opacity: 0.9;
	text-align: left;
}
