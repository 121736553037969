.slider-group-container {
	/* padding: 20px 40px 20px 40px; */
	border-radius: 10px;
	background: white;
	font-size: 14px;
}

.slider-group-title {
	font-size: 18px;
	font-weight: bold;
	text-align: left;
}

.slider-group-label {
	font-size: 14px;
	text-align: left;
	width: 350px;
	margin-bottom: 20px;
}

.slider-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 350px;
	/* margin: 0px auto; */
	/* outline: 2px solid red; */

	/* display: flex; */
}

.slider-list > li {
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	flex-direction: column;
}

.input-slider {
	width: 300px;
	transition: opacity 0.35s;
}

.input-slider::-webkit-slider-runnable-track {
	height: 10px;
}

.slider-list > li > label {
	text-align: left;
}

.input-slider {
	-webkit-appearance: none;
	appearance: none;
	background: #001940;
	opacity: 0.8;
	height: 25px;
	outline: none;
	/* border-radius: 1rem; */
	/* margin-right: 40px; */
	border-radius: 3px;
	margin: 0;
	padding: 0;
}

.input-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 10px;
	height: 27px;
	background: rgb(233, 233, 233);
	box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
	outline: 1px solid rgb(40, 40, 40, 0.35);
	cursor: pointer;
	border-radius: 2px;
	margin-top: -9px;
}

.lock-button {
	margin: 0;
	border: none;
	background: transparent;
	color: #001940;
}

.lock-button:hover {
	cursor: pointer;
}
