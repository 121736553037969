.dropdown-list,
.current-item {
	background: white;
	color: rgb(40, 40, 40);
}

.dropdown-list-item {
	font-size: 16px;
	padding: 5px 0 5px 15px;
	display: flex;
	flex-direction: column;
	color: #001940;
	box-sizing: border-box;
	border-left: 5px solid transparent;
	width: 100%;
}

.dropdown-list-item:hover {
	cursor: pointer;
	border-left: 5px solid #001940dd;
	background-color: #00194011;
}

.dropdown-list {
	padding: 5px 0px;
	z-index: 5;
	list-style-type: none;
	position: absolute;
	top: 90%;
	max-height: 410px;
	width: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: left;
	animation: grow 0.25s ease-in-out;
}

@keyframes grow {
	0% {
		/* max-height: 0px; */
		opacity: 0.5;
		overflow-y: hidden;
		transform: translateY(-10px);
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translateY(0px);
		/* max-height: 410px; */
		opacity: 1;
	}
}

.dropdown-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
}

.current-item {
	height: 70px;
	width: 280px;
	padding: 10px 12px 10px 20px;
	/* border-radius: 10px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	/* outline: 1px solid rgb(60, 60, 60, 0.5); */
	/* box-shadow: rgba(0, 0, 0, 0.3) 0 1px 6px; */
	border: none;
	font-family: 'Poppins', sans-serif;
	/* box-sizing: border-box; */
}

.current-item:hover {
	cursor: pointer;
}
