/* @import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'); */

:root {
	--primary-color-one: #001940;
	--primary-color-two: #0066b2;
	--btr-blue: rgb(0, 52, 135);
	--button-text: rgb(2, 2, 150);
	--background-color-one: rgb(138, 190, 206);
	--background-color-two: rgb(210, 212, 216);
	--green: #32a846;
	--error-red: #d13313;
	--faded-black: rgb(40, 40, 40, 0.8);
	--bold: 600;
	--semi-bold: 500;
	--box-shadow-one: rgb(0, 0, 0, 0.3) 0px 1px 2px 2px;
}

* {
	scrollbar-width: thin;
	scrollbar-color: #0019408f white;
	/* -moz-osx-font-smoothing: grayscale; */
	text-rendering: optimizeLegibility;
	font-family: 'Poppins', sans-serif !important;
}

.App {
	text-align: center;
	/* background-color: rgb(206, 239, 250); */
	background-color: rgb(138, 190, 206);
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Poppins', sans-serif;
	letter-spacing: 1;
}

input[type='radio'] {
	color: var(--primary-color-one);
}

input[type='radio']:hover {
	cursor: pointer;
}

button {
	font-family: inherit;
	padding: 0;
	border: 0;
	background: transparent;
	cursor: pointer;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #001940;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.MainViewPort {
	/* background-color: #d6d6d6; */
	background-color: rgb(210, 212, 216);
	/* height: 650px; */
	height: clamp(650px, 90vh, 1000px);
	/* width: 1300px; */
	width: clamp(825px, 90vw, 1300px);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.3) 0 1px 20px;
	border-radius: 6px;
	padding-left: 70px;
	overflow-x: hidden;
}

.HomeGraphContainer {
	margin-left: 100px;
	height: 200px;
	width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 40px 40px;
}

.main-container {
	position: relative;
	display: flex;
	justify-content: center;
	width: auto;
	/* height: 650px; */
	height: clamp(650px, 90vh, 1000px);
}

.form-container {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	/* box-shadow: var(--box-shadow-one); */
	padding: 30px 40px 10px 30px;
	padding-top: 20px;
	/* border-radius: 10px; */
	background: white;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

h2 {
	font-size: 20px;
	font-weight: var(--bold);
	color: #001940;
	align-items: center;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.section-description {
	font-size: 14px;
	opacity: 0.9;
	text-align: left;
	margin-top: 0px;
}

@media (max-width: 1475px) {
	/* .MainViewPort {
		width: 1000px;
	} */
}

@media (max-width: 1175px) {
	/* .MainViewPort {
		width: 825px;
	} */
	.HomeGraphContainer {
		width: 450px;
	}
}

/* @media (max-height: 650px) {
	.MainViewPort {
		height: 550px;
	}
} */

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background: #0019408f;
	border-radius: 25px;
}

.submit-button-one,
.cancel-button-one {
	border: none;
	border-radius: 5px;
	padding: 0 20px;
	font-size: 14px;
	height: 30px;
	transition: all 0.2s;
}

.submit-button-one:hover,
.cancel-button-one:hover {
	transform: translateY(-1px);
	opacity: 0.8;
}

.submit-button-one {
	outline: none;
	background: #001940;
	color: white;
}

.cancel-button-one {
	outline: 1.5px solid #001940;
	color: #001940;
}
